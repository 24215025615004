import React from 'react'
import { Link } from 'gatsby'

import '../styles/styles.scss'

import LOGO from '../img/logo/logo.svg'
import PREFOOTER from '../img/backgrounds/bg-pre-footer-inside.jpg'

// ICONS
import { IoMdClose } from 'react-icons/io'
import { FiArrowRight } from 'react-icons/fi'

import { StickyContainer, Sticky } from 'react-sticky'
// import { LocomotiveScrollProvider } from 'react-locomotive-scroll'

// import 'locomotive-scroll/dist/locomotive-scroll.css'

export function PreFooter({
  href,
  linkText,
  linkTo,
  preTitle,
  styles,
  title,
  titleClickable,
}) {
  return (
    <div className="pre-footer">
      <div className="container aligncenter" style={styles}>
        <div className="columns post-single ui-grid">
          <div className="column is-8">
            {preTitle && <p className="pre-title">{preTitle}</p>}

            {titleClickable ? (
              href ? (
                <a href={href}>
                  <h3>{title}</h3>
                </a>
              ) : (
                <Link to={linkTo}>
                  <h3>{title}</h3>
                </Link>
              )
            ) : (
              <>
                <h3>{title}</h3>
              </>
            )}

            {href ? (
              <a href={href}>
                {linkText} <FiArrowRight />
              </a>
            ) : (
              <Link to={linkTo}>
                {linkText} <FiArrowRight />
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="cp-pulse"></div>

      <img className="cp-pulse" src={PREFOOTER} />
    </div>
  )
}

class Header extends React.Component {
  render() {
    const { props } = this
    const { HeaderExtension } = props

    return (
      <>
        <div className="site-header">
          <div className="container">
            <nav
              className="navbar"
              role="navigation"
              aria-label="main navigation"
            >
              <div className="navbar-brand">
                <Link className="navbar-item logo" to="/">
                  <img src={LOGO} />
                </Link>

                <a
                  role="button"
                  className="navbar-burger burger"
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="navbarBasicExample"
                  onClick={this.props.handleSideNavToggle}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>

              <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start"></div>

                <div className="navbar-end">
                  {/* <Link className="navbar-item link" to="/#">
                    Team
                  </Link> */}

                  {/* 
                  <Link className="navbar-item link" to="/press">
                    Press
                  </Link> */}

                  <Link className="navbar-item link" to="/for-media">
                    For media
                  </Link>

                  <Link className="navbar-item link" to="/for-brands">
                    For brands
                  </Link>

                  <Link className="navbar-item link" to="/shows">
                    Our shows
                  </Link>

                  <a
                    className="navbar-item link"
                    href="https://missioncontrol.knocking.com"
                    target="_blank"
                  >
                    Mission Control
                  </a>

                  <Link className="navbar-item link" to="/contact">
                    <span>Contact us</span>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>

        {HeaderExtension}
      </>
    )
  }
}

export const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="columns bottom-links is-vcentered">
            <div className="column is-narrow">
              <Link to="/">
                <img src={LOGO} className="logo" />
              </Link>
            </div>

            <div className="column center-links">
              <div className="last">
                <Link to="/press">Press</Link>
              </div>

              <div>
                <Link to="/careers">Careers</Link>
              </div>
            </div>

            <div className="column right-links">
              <div className="last">
                <Link to="/terms-of-use">Terms of use</Link>
              </div>

              <div>
                <Link to="/privacy-policy">Privacy policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

const Layout = props => {
  const containerRef = React.useRef(null)

  const [sideNav, setSideNav] = React.useState(false)

  const handleSideNavToggle = () => {
    if (sideNav === true) {
      document.querySelector('html').style.overflowY = 'auto'
    } else {
      document.querySelector('html').style.overflowY = 'hidden'
    }

    setSideNav(!sideNav)
  }

  const { children, location } = props

  return (
    // <LocomotiveScrollProvider
    //   options={{
    //     smooth: true,
    //     // ... all available Locomotive Scroll instance options
    //   }}
    //   // watch={
    //   //   [
    //   //     //...all the dependencies you want to watch to update the scroll
    //   //   ]
    //   // }
    //   containerRef={containerRef}
    // >
    <div className="app-wrapper" ref={containerRef}>
      <StickyContainer>
        <div className={'sidenav' + (sideNav ? ' open' : '')}>
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-narrow logo-wrapper">
                <Link className="" to="/">
                  <img src={LOGO} />
                </Link>
              </div>
              <div className="column close-wrapper">
                <div className="close">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      handleSideNavToggle()
                    }}
                  >
                    <IoMdClose />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="links">
            <Link to={`/`} onClick={handleSideNavToggle}>
              Home
            </Link>

            {/* <Link to="/contact" onClick={handleSideNavToggle}>
                Team
              </Link> */}

            <Link to="/for-media" onClick={handleSideNavToggle}>
              For media
            </Link>

            <Link to="/for-brands" onClick={handleSideNavToggle}>
              For brands
            </Link>

            <Link to="/shows" onClick={handleSideNavToggle}>
              Our shows
            </Link>

            <Link to="/press" onClick={handleSideNavToggle}>
              Press
            </Link>

            <Link to="/careers" onClick={handleSideNavToggle}>
              Careers
            </Link>

            <a
              onClick={handleSideNavToggle}
              href="https://missioncontrol.knocking.com"
              target="_blank"
            >
              Mission Control
            </a>

            <Link to="/contact" onClick={handleSideNavToggle}>
              <span>Contact us</span>
            </Link>
          </div>
        </div>

        <div className={'app-container' + (sideNav ? ' sidenav-open' : '')}>
          <div className="overlay" onClick={handleSideNavToggle}></div>

          <Header handleSideNavToggle={handleSideNavToggle} {...props} />

          {children}

          <Footer location={location} />
        </div>
      </StickyContainer>
    </div>
    // </LocomotiveScrollProvider>
  )
}

export default Layout
